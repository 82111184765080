import styled from '@emotion/styled';
import { graphql, Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import * as React from 'react';

import {
  Columns,
  ContentBlock,
  Left,
  Right,
  TextBlock,
} from '../components/Blocks';
import { Insert } from '../components/Insert';
import Layout from '../components/layout';
import css from '@emotion/css';

export default ({ data }: any) => {
  const galleries = data.allContentfulFotogalerie.edges;

  const split = Math.max(1, Math.ceil(galleries.length / 2));

  return (
    <Layout>
      <TextBlock
        css={css`
          @media (max-width: 800px) {
            padding-top: 4rem;
            padding-bottom: 0;
          }
        `}
      >
        <Insert>
          Behind every picture <br />
          <em>there is a story</em> to discover
        </Insert>
      </TextBlock>

      <ContentBlock>
        <Columns>
          <Left>
            {galleries.slice(0, split).map((gallery: any) => (
              <GalleryPreview key={gallery.node.id} {...gallery} />
            ))}
          </Left>

          <Right>
            {galleries.slice(split).map((gallery: any) => (
              <GalleryPreview key={gallery.node.id} {...gallery} />
            ))}
          </Right>
        </Columns>
      </ContentBlock>
    </Layout>
  );
};

const GalleryPreview = (props: any) => {
  return (
    <Box to={`/fotografie/${props.node.url}`}>
      <PreviewImage fluid={props.node.poster.fluid} />
      <GalleryData>
        <Title>{props.node.title}</Title>
        <Credit>{props.node.photographer}</Credit>
      </GalleryData>
    </Box>
  );
};

const Box = styled(Link)`
  display: block;
  margin-bottom: 2rem;
  text-decoration: none;

  clear: both;
`;

const PreviewImage = styled(GatsbyImage)`
  clear: left;
  margin-bottom: 0.5rem;
`;

const GalleryData = styled.div`
  @media (max-width: 800px) {
    padding: 1rem 2rem 3rem 2rem;
  }

  ::before,
  ::after {
    content: '';
    display: table;
  }
`;

const Title = styled.h2`
  float: left;
  font-size: 1rem;
  font-weight: 800;
  margin: 0;
  line-height: 1rem;
`;
const Credit = styled.span`
  float: right;
  font-size: 0.8rem;
  font-family: Lora;
  font-weight: 400;
  font-style: italic;
  color: #3c434c;
  line-height: 1rem;
`;

export const query = graphql`
  {
    allContentfulFotogalerie {
      edges {
        node {
          id
          title
          url
          photographer
          poster {
            fluid(maxWidth: 540) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;
